@font-face {
  font-family: NotoSerifHebrew;
  src: url("./fonts/NotoSerifHebrew.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: NotoSerifHebrew;
  src: url("./fonts/NotoSerifHebrew.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 0% 200%;
}

@font-face {
  font-family: DanaYad;
  src: url("./fonts/DanaYad.otf") format("opentype");
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: NotoSerifHebrew;
}

.regular {
  font-family: NotoSerifHebrew;
  font-size: 1rem;
}

p {
  direction: rtl;
}
p.eng,
.text-left {
  direction: ltr;
  text-align: left;
}

.App {
  text-align: center;
  color: #000;
}

.hand {
  font-family: DanaYad;
  font-size: 1.5rem;
}

.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}
.small-text {
  font-size: 14px;
}

.App-link {
  /* color: #61dafb; */
  cursor: pointer;
  text-decoration: underline;
}
.tooltip a {
  text-decoration: none;
}
a[href^="#"] {
  color: #ba7a35;
  /* border-bottom: 1px dotted; */
}
h3 {
  scroll-margin-top: 20px;
}

.doc-content ul li,
p.highlight {
  font-size: 1.3em;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  min-width: 172px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  font-size: 1rem;

  /* Position the tooltip text - see examples below! */
  top: calc(100% + 8px);
  left: -50%;
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.modal-button,
#modal-button {
  color: green;
  cursor: pointer;
  text-decoration: underline;
}

.dashed_bullets ul {
  list-style: none;
  margin-right: 0;
  padding-right: 4px;
}
.dashed_bullets li.li-bullet-0:before {
  content: "-";
}
.image-gallery {
  width: 100%;
}
.image-gallery-content:not(.fullscreen) .image-gallery-image {
  max-width: 800px;
  max-height: 60vh !important;
}

for zoom on scroll .image-gallery-image {
  width: 100%;
  height: 100%;
  transform-origin: 0px 0px;
  transform: scale(1) translate(0px, 0px);
  cursor: grab;
}

.image-gallery-thumbnail-image {
  max-width: 84px;
  width: 100%;
}
div .image-gallery-left-nav .image-gallery-svg,
div .image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: initial;
}

aside.ps-sidebar-root {
  border-right: none;
}

.home {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  /* padding: 0 20px; */
}
.home .title {
  color: #512a1b;
  margin: 0px 0px;
  font-size: 1.75em;
  padding: 0px 32px;
}

.space-bottom,
p:first-of-type.italic {
  margin-bottom: 28px;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 15px;
  }
}
